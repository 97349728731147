import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { graphql, useStaticQuery } from "gatsby";
import { TermsOfUseQuery } from "../../../graphql-types";
import ElevateButton from "../ElevateButton";
import rehypeRaw from "rehype-raw";

interface TermsOfUseComponentProps {
    onAccept(): void;
    onDecline(): void;
}

const TermsOfUse: React.FC<TermsOfUseComponentProps> = ({ onAccept, onDecline }) => {
    const queryResults = useStaticQuery<TermsOfUseQuery>(query);
    return (
        <div className="p-5 w-full h-full flex">
            <div className="max-w-4xl border-gray-light border rounded-md bg-white p-5 m-auto overflow-scroll overflow-x-hidden max-h-[calc(70vh)]">
                <ReactMarkdown 
                children={queryResults.terms?.text?.data?.childMarkdownRemark?.rawMarkdownBody || ""} 
                className="prose"
                components={{ u: ({ node, ...props }) => <u style={{ textDecoration: "underline" }} {...props} />}}
                rehypePlugins={[rehypeRaw]} 

                />
                <div className="flex justify-center">
                    <ElevateButton className="my-5 mr-5" onClick={() => onAccept()}>I accept</ElevateButton>
                    <ElevateButton className="my-5 ml-5" onClick={() => onDecline()}>I decline</ElevateButton>
                </div>
            </div>
        </div>
    )
}

export default TermsOfUse;

export const query = graphql`
  query TermsOfUse {
    terms: strapiLegalDocument(relativePath: {eq: "terms_of_use"}) {
        text {
            data {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
        }
    }
  }
`;