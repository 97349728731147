import React, { useState, useEffect } from "react";
import ElevateButton from "../ElevateButton";
import MenuBar from "../MenuBar";
import Cookies from "js-cookie";
import logo from "../../assets/northstar/northstar_logo_alt.png"
import backgroundImage from "../../assets/northstar/bg_img.png";
import TermsOfUse from "../TermsOfUse";
import { navigate } from "gatsby";
import northstarClient from "../../services/northstarClient";
interface NorthstarLoginProps {
    setLoginStatus(status: boolean): void
}

const NorthstarLogin: React.FC<NorthstarLoginProps> = ({ setLoginStatus }) => {
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [showError, setShowError] = useState<boolean>(false);
    const [showTOU, setShowTOU] = useState<boolean>(false);
    const [tableauJwtElev, setTableauJwtElev] = useState<string | null>(null);
    const [tableauUserElev, setTableauUserElev] = useState<string | null>(null);
    const [tableauApiTokenElev, setTableauApiTokenElev] = useState<string | null>(null);
    const GATSBY_API_URL = process.env.GATSBY_API_URL;

    const isFormFilled = () => {
        return (!!username.length && !!password.length)
    }

    const userLogin = async () => {
        try {
            // DISABLING COGNITO LOGIN FOR NOW, NOT NEEDED
            // const user = await Auth.signIn(username, password);
            const loginRes = await northstarClient.post(
                `${GATSBY_API_URL}/api/tableau/authenticate`,
                {
                    username,
                    password
                },
                false
            );

            setTableauJwtElev(loginRes.jwt);
            setTableauUserElev(username);
            setTableauApiTokenElev(loginRes.tableauResponse.credentials.token);

            // show TOU form if user hasn't accepted terms before
            const touRes = await northstarClient.get(
                `${GATSBY_API_URL}/api/tou/verify-acceptance?username=${username}`,
                loginRes.tableauResponse.credentials.token
            );

            if (touRes["accepted"]) {
                Cookies.set("tableauJwtElev", loginRes.jwt, { expires: 1 / 192 }); // expires in seven minutes
                Cookies.set("tableauUserElev", username, { expires: 1 }); //  expires in 1 day
                Cookies.set("tableauApiTokenElev", loginRes.tableauResponse.credentials.token, { expires: 0.125 }); // expires in three hours

                setLoginStatus(true);
            }
            else setShowTOU(true);
        }
        catch (error) {
            // console.log("error signing in: ", error);
            setLoginStatus(false);
            setShowError(true);
        }
    }

    const onAccept = async () => {
        try {
            const url = `${GATSBY_API_URL}/api/tou/record-acceptance`;
            // store user acceptance of TOUs
            await northstarClient.post(
                `${GATSBY_API_URL}/api/tou/record-acceptance`,
                {
                    username: tableauUserElev
                }
            );

            Cookies.set("tableauJwtElev", tableauJwtElev, { expires: 1 / 192 }); // expires in seven minutes
            Cookies.set("tableauUserElev", tableauUserElev, { expires: 1 }); //  expires in 1 day
            Cookies.set("tableauApiTokenElev", tableauApiTokenElev, { expires: 0.125 }); // expires in three hours

            setLoginStatus(true);
        }
        catch (err) {
            // TODO: ADD ERROR LOGGING
            navigate("/northstar/error");
        }
    }

    const onDecline = () => {
        setShowTOU(false);
    }

    return (
        <>
            <MenuBar NorthstarMenu={true} />
            {
                !showTOU &&
                <div className="px-8 pt-24 md:pt-32 bg-white flex justify-center items-center h-full w-full"
                    style={{
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "bottom"
                    }}
                >
                    <div className="bg-off-white h-[440px] w-[350px] xs:h-[452px] xs:w-[360px] border border-gray-light rounded-md">
                        <div className="p-8">
                            <div className="py-4 flex flex-col items-center">
                                <img src={logo} alt=""></img>
                                {
                                    /* ZeroFox beacon */
                                    process.env.GATSBY_APPLICATION_URL === "https://www.bamelevate.com" && <img src="https://d21y75miwcfqoq.cloudfront.net/f9b407c1" style={{ position: "absolute" }} referrerPolicy="no-referrer-when-downgrade" />
                                }
                            </div>
                            <form>
                                <input type="text" className="border border-gray-light rounded p-2 text-gray-dark font-sans mb-6 input-field w-full" placeholder="Username" onChange={(e) => { setUsername(e.target.value) }}></input>
                                <input type="password" className="border border-gray-light rounded p-2 text-gray-dark font-sans mb-6 input-field w-full" placeholder="Password" onChange={(e) => { setPassword(e.target.value) }}></input>
                            </form>
                            {/* <form>
                                <input type="text" className="border border-gray-light rounded p-2 text-gray-dark font-sans mb-6 input-field w-full" placeholder="Username" onChange={(e) => { setUsername(e.target.value) }}></input>
                                <input type="text" className="border border-gray-light rounded p-2 text-gray-dark font-sans mb-6 input-field w-full" placeholder="PAT Name" onChange={(e) => { setPatName(e.target.value) }}></input>
                                <input type="password" className="border border-gray-light rounded p-2 text-gray-dark font-sans mb-6 input-field w-full" placeholder="PAT Value" onChange={(e) => { setPatValue(e.target.value) }}></input>
                            </form> */}
                            <div className={`col-span-2 text-red text-xs ${showError ? "" : "hidden"}`}>Invalid username or password.</div>
                            <div className="w-full">
                                <ElevateButton disabled={!isFormFilled()} className="mt-4 w-full" onClick={() => userLogin()}>Login</ElevateButton>
                                {/* <ElevateButton disabled={false} className="mt-4 w-full" onClick={() => userLoginPat()}>Login</ElevateButton> */}
                            </div>
                            {/* <div className="py-4 text-sm cursor-pointer">
                            Forgot your password? <a className="underline text-blue">Reset it</a>
                        </div> */}
                        </div>
                    </div>
                </div>
            }

            {
                showTOU &&
                <TermsOfUse onAccept={onAccept} onDecline={onDecline} />
            }
        </>
    )
}

export default NorthstarLogin