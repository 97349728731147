import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import NorthstarLogin from "../../components/Login";
import { navigate } from "gatsby";
import Cookies from "js-cookie";

const LoginPage: React.FC = () => {
    const [isLoggedIn, loggedIn] = useState(false);

    useEffect(()=> {
        if(Cookies.get("tableauUserElev") && Cookies.get("tableauApiTokenElev")) navigate("/northstar/home");
    })

    const setLoginStatus = (status: boolean): void => {
        if (status) navigate("/northstar/home")
        else loggedIn(status);
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Login Page</title>
            </Helmet>

            <main className="w-screen h-screen bg-off-white flex flex-center">
                {!isLoggedIn &&
                    <NorthstarLogin setLoginStatus={setLoginStatus} />
                }
            </main>
        </>
    )
}

export default LoginPage;